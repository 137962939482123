.totalpostcontent {
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
}

@media all and (max-width: 512px) {
  .totalpostcontent {
    flex-direction:column;
  }
  
}

.posttext {
    padding:10px;
}

.headerinfo {
  max-width: 500px;
  border-radius: 10px;
  margin-top: 10px;

}


@media all and (max-width: 512px) {
  .headerinfo {
    max-width:100%;
    }
  
}


.postfigures {
    max-width:300px;
    border-radius: 10px;
    margin-top: 10px;
}

@media all and (max-width: 512px) {
  .postfigures {
    max-width:100%;
    }
  
}

.imagescontainer {
    display: flex;
    flex-direction: column;
    justify-content:center;
}