.zcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.zcontainer  img {
 border: 2px solid #ddd;
  border-radius: 9px;
  padding: 5px;
  width: 18rem;
  }
.zcontainer img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}



.zcontainer > div {
  
  margin: 10px;
  padding: 20px;
  
}
@media screen and (max-width: 480px) {
  .zcontainer {    
    flex-direction: column;
    align-items: center;
  }
}