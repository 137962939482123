// Global styling for this template
body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: $roboto-font;
  background-color: #fff;
}

.col-lg-10.my-auto {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

hr {
  max-width: 50px;
  border-width: 3px;
  border-color: $primary;
}

hr.light {
  border-color: $white;
}



a {
  color: $primary;
  @include transition-all;
  &:hover {
    color: darken($primary, .1);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif-font;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-dark {
  background-color: $gray-900 !important;
}

.text-faded {
  color: fade-out($white, .3);
}

.jumbotron1 {
  height: 250px;
  margin-bottom: 30px;
  background-position: center center;
  @include background-cover;
}

section {
  padding: 4rem 0;
}

.section-heading {
  margin-top: 0;
}

::-moz-selection {
  color: $white;
  background: $gray-900;
  text-shadow: none;
}

::selection {
  color: $white;
  background: $gray-900;
  text-shadow: none;
}

img::selection {
  color: $white;
  background: transparent;
}

img::-moz-selection {
  color: $white;
  background: transparent;
}
// Global styling for this template footer
#contact {
  background-color: $gray-300;
}
i {
    color: #01579b;
}
.btnm {
    font-family: Oswald;
  background-color: orange;
  color: white;
  text-decoration: none;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  max-width:80%; 
}
